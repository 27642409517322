import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import TopNavBar from '../components/TopNavBar';

const campingImage = require('../assets/img/frogo-404-page-camping.svg');

const calmBrandColor = '#c7eeeb';

const NotFoundPage = () => (
  <Layout>
    <div
      style={{
        position: 'relative',
        height: '100vh'
      }}
      className="bg-primary"
    >
      <TopNavBar transparent={true} logoColor={calmBrandColor} />
      <div
        className="bg-primary"
        style={{
          position: 'absolute',
          top: '0',
          height: '50vh',
          width: '100%'
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '50vh',
          height: '50vh',
          width: '100%',
          backgroundColor: '#41AFA4'
        }}
      />
      <div className="h-100 d-flex align-items-center">
        <Container fluid={true}>
          <Row>
            <Col xs={12} md={6} className="pt-5 pt-md-0 text-center">
              <img
                src={campingImage}
                className="img-fluid"
                style={{
                  maxWidth: '80%'
                }}
                alt="Campingplatz"
              />
            </Col>
            <Col
              xs={12}
              md={6}
              className="pt-2 pt-md-0 d-flex flex-column justify-content-center"
            >
              <Row className="h-50">
                <Col className="align-self-end">
                  <h1 className="text-white">404 – PAGE NOT FOUND</h1>
                </Col>
              </Row>
              <Row className="h-50">
                <Col className="align-self-start">
                  <p
                    className="mt-2 mb-0 lead"
                    style={{
                      color: calmBrandColor
                    }}
                  >
                    Ooops, du hast dicht wohl verirrt?!
                  </p>
                  <p
                    className="lead"
                    style={{
                      color: calmBrandColor
                    }}
                  >
                    Hier geht es zurück zur{' '}
                    <Link to="/" className="text-white font-weight-normal">
                      Startseite
                    </Link>
                    .
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
